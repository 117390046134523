// Anglar
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
// Layout Directives
// Services
import { ContentAnimateDirective, FirstLetterPipe, GetObjectPipe, HeaderDirective, JoinPipe, CustomDatePipe, CustomCurrencyFormatPipe, TextFormatPipe, TotalHoursPipe, FirstLetterOnlyPipe, MenuDirective, OffcanvasDirective, SafePipe, ScrollTopDirective, SparklineChartDirective, StickyDirective, TabClickEventDirective, NumberDirective, TimeElapsedPipe, ToggleDirective, TruncatePipe, SplitStringPipe, TotalHoursWithoutSecsPipe, TwoDigitDecimalNumberDirective, AppDateFormatterDirective, DiscountonlyDirective, AadharCardFormatDirective } from "./_base/layout";

import { AppfactoryService } from "./service/appfactory.service";

//
import { CookieService } from "ngx-cookie-service";
// Crud Services
import { ExcelService, AddressService, TotalRecordsService, SubscriptionsService } from "./_base/crud";
import { CharactersDirective } from "./_base/layout/directives/characters.directive";
import { HoursTotimePipe } from './_base/layout/pipes/hours-totime.pipe';
import { AlphaNumericDirective } from './_base/layout/directives/alpha-numeric.directive';
@NgModule({
	imports: [CommonModule],
	declarations: [
		// directives
		ScrollTopDirective,
		HeaderDirective,
		OffcanvasDirective,
		ToggleDirective,
		MenuDirective,
		TabClickEventDirective,
		SparklineChartDirective,
		ContentAnimateDirective,
		CharactersDirective,
		StickyDirective,
		NumberDirective,
		TwoDigitDecimalNumberDirective,
		AppDateFormatterDirective,
		DiscountonlyDirective,
		AadharCardFormatDirective,
		// pipes
		TimeElapsedPipe,
		JoinPipe,
		GetObjectPipe,
		SafePipe,
		FirstLetterPipe,
		CustomDatePipe,
		TextFormatPipe,
		FirstLetterOnlyPipe,
		TotalHoursPipe,
		TruncatePipe,
		SplitStringPipe,
		CustomCurrencyFormatPipe,
		TotalHoursWithoutSecsPipe,
		HoursTotimePipe,
		AlphaNumericDirective,
	],
	exports: [
		// directives
		ScrollTopDirective,
		HeaderDirective,
		OffcanvasDirective,
		ToggleDirective,
		MenuDirective,
		TabClickEventDirective,
		SparklineChartDirective,
		ContentAnimateDirective,
		StickyDirective,
		NumberDirective,
		AppDateFormatterDirective,
		TwoDigitDecimalNumberDirective,
		DiscountonlyDirective,
		AadharCardFormatDirective,
		// pipes
		TimeElapsedPipe,
		JoinPipe,
		GetObjectPipe,
		SafePipe,
		FirstLetterPipe,
		CustomDatePipe,
		CustomCurrencyFormatPipe,
		TextFormatPipe,
		FirstLetterOnlyPipe,
		TotalHoursPipe,
		TruncatePipe,
		SplitStringPipe,
		TotalHoursWithoutSecsPipe,
		HoursTotimePipe,
		CharactersDirective,
		AlphaNumericDirective
	],
	providers: [CookieService, CustomDatePipe, CustomCurrencyFormatPipe, FirstLetterPipe, TotalHoursPipe, ExcelService, AddressService, TotalRecordsService, TotalHoursWithoutSecsPipe, AppfactoryService, SubscriptionsService],
})
export class CoreModule {}
