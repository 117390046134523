// Angular
import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, ElementRef, ViewChild, SimpleChanges } from '@angular/core';
// RxJS
import { BehaviorSubject, Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, map, finalize } from 'rxjs/operators';

import { CustomersService } from '../../../../../../../core/crm/customers';
import { FormControl, Validators } from '@angular/forms';
import { FormDialogComponent } from './form-dialog/form-dialog.component'
// Modal
import { MatDialog } from '@angular/material/dialog';

// Component
import { DialogComponent } from './dialog/dialog.component';

@Component({
	selector: 'kt-timeline',
	templateUrl: './timeline.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: ['.kt-transaction-content{height: 59vh; overflow-y: auto; overflow-x: hidden; padding-right: 10px;}',
		'.timelineStatucIcon{display: inline-block; width: 20px; height: 20px; border-radius: 50%; text-align: center; color: white; padding: 2px;}',
		'.searchBar{position:absolute;right:0}']
})
export class TimelineComponent implements OnInit {
	@Input() loadingSubject = new BehaviorSubject<boolean>(false);
	@ViewChild('searchInput', { static: true }) searchInput: ElementRef;
	@ViewChild('transactionContent', { static: true }) private transactionContentContainer: ElementRef;
	@Input() customerId: number;
	@Input() statusList
	@Input() referralList;
	@Input() userName; 
	@Input() referByCode; 
	customerStatusList;
	comment = new FormControl('', Validators.compose([Validators.pattern(/(\W{20,}|\x20{1}|[^\s]{1})/g), Validators.maxLength(2000)]));
	customerTransactionList: any = [];
	formsection: any = []
	formdata :any=[]
	add: any = []
	customer = {
		From: 0,
		To: 50,
		LeadId: null,
		Text: '',
		StatusIds: '',
		IsVisitForm: '',
		IsCheckIn: '',
		IsProductsAvailable: ''
	}
	loadingTransaction = false;
	private subscriptions: Subscription[] = [];

	constructor(
		private ref: ChangeDetectorRef,
		private customersService: CustomersService,
		public dialog: MatDialog) { }

	ngOnInit() {
		console.log(this.userName)
		this.getCustomerStatus();
	}

	ngAfterViewInit() {
		const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
			debounceTime(50),
			distinctUntilChanged(),
			tap(() => {
				this.getCustomerTransaction();
			})
		)
			.subscribe();
		this.subscriptions.push(searchSubscription);	
	}

	ngAfterViewChecked() {
		this.scrollToBottom();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.customerId && this.customerId > 0 && this.customerTransactionList) {
			this.getCustomerTransaction();
		}	
	}

	getCustomerStatus() {
		this.customersService.getCustomerStatus()
			.pipe(
				map(res => {
					if (res != '') {
						this.customerStatusList = res;
						this.ref.detectChanges();
					}
				})
			)
			.subscribe();
	}

	getCustomerTransaction() {
		this.customerTransactionList = [];
		this.loadingTransaction = true;
		// this.ref.detectChanges();
			this.customer.LeadId = this.customerId;
			this.customer.Text = this.searchInput.nativeElement.value;
			this.customersService.customerTransaction(this.customer)
				.pipe(
					map(res => {
						if (res) {
							this.customerTransactionList = res.reverse();
							setTimeout(() => {
								var ObjectiveArr = [];
								for (const transaction of this.customerTransactionList) {
									if (transaction.Objective) {
										ObjectiveArr = transaction.Objective.split(",");
									}
									transaction.ObjectiveArr = ObjectiveArr;
								}
								this.scrollToBottom();
								this.ref.detectChanges();
								
							});
						}else{
							this.customerTransactionList = [];
						}
						// this.ref.detectChanges();
					}),
					finalize(() => {
						this.loadingTransaction = false; // Set loading to false when the request completes
						  this.ref.detectChanges();
					})
				)
			.subscribe();
	}

	getTransactionObjective(objective: any) {
		if (!objective) {
			return []
		}

		return objective.split(',');
	}



	onEnter() {
		if (!this.comment.invalid) {
			const _customerComment = {
				LeadId: this.customerId,
				Note: this.comment.value
			}
			this.customersService.commentOnCustomerTransactions(_customerComment)
				.pipe(
					map(res => {
						this.getCustomerTransaction();
						this.comment.patchValue('');
						this.ref.detectChanges();
						this.scrollToBottom();
					})
				)
				.subscribe();
		}
	}

	scrollToBottom() {
		try {
			this.transactionContentContainer.nativeElement.scrollTop = this.transactionContentContainer.nativeElement.scrollHeight;
		} catch (err) { }
	}

	applyFilter(data) {
		this.customer.StatusIds = data.filterData.StatusIds;
		this.getCustomerTransaction();
	}

	openInDialog(dataFrom, transaction) {
		this.dialog.open(DialogComponent, {data: {transaction: transaction,showWindow: dataFrom},width:'800px'});
	}
	formdetail(data) {
		this.formsection = data.FormSection;
        this.formdata = data.FormSection[0].Controls;
		 this.dialog.open(FormDialogComponent, {
			data: { data:this.formdata ,section :this.formsection }, width: '500px', height: 'auto'
		  });


	}

	getDetails(transaction) {
		this.customersService.getdetails(transaction)
			.pipe(map(res => {
				if (res.Status == 200) {
					this.add = res.data
					this.formdetail(this.add)
				}
			})).subscribe()
	}



}