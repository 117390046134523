import { Directive, ElementRef, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
	selector: "input[charactersOnly]",
	exportAs: "charactersOnly",
})
export class CharactersDirective {
	constructor(private _el: ElementRef,private control?: NgControl) {}

	@HostListener("input", ["$event"]) onInputChange(event) {
		const initialValue = this._el.nativeElement.value;
		this._el.nativeElement.value = initialValue.replace(/[^a-zA-Z]*/g, "");
		if (initialValue !== this._el.nativeElement.value) {
			// Update the FormControl or ngModel with the cleaned value
		if (this.control && this.control.control) {
			this.control.control.setValue(this._el.nativeElement.value, { emitEvent: false });
		}
			event.stopPropagation();
		}
	}
}
