import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAadharCardFormat]'
})
export class AadharCardFormatDirective {

  private previousValue: string = '';

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInputChange(event: KeyboardEvent) {
    const input = this.el.nativeElement;
    let value = input.value.replace(/\s+/g, '').replace(/[^0-9]/g, '');

    if (value.length > 12) {
      value = value.substr(0, 12);
    }

    if (value !== this.previousValue.replace(/\s+/g, '')) {
      this.previousValue = value.replace(/(\d{4})(\d{4})(\d{4})/, '$1 $2 $3');
      input.value = this.previousValue;
    }
  }

  @HostListener('blur')
  onBlur() {
    const input = this.el.nativeElement;
    input.value = this.previousValue;
  }
}
