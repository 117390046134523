import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hoursTotime'
})
export class HoursTotimePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
   if(!value){
    return ;
   }
   let hours = value.toString().padStart(2,'0');
   return `${hours}:00`
  }

}
